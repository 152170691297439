/* Enter your styles here */

[js-table-list-check-area]:hover .table-list__check-box {
  border-color: #6d6d6d;
}

[js-inactive-project]:hover [js-inactive-project-texts] {
  opacity: 100;
}

/* custom scroll bar */

div::-webkit-scrollbar-button {
  background-color: #eaeaea;
  height: 0;
}

div::-webkit-scrollbar-track {
  background-color: transparent;
}

div::-webkit-scrollbar-track-piece {
  background-color: #F1F1F3;
}

div:hover::-webkit-scrollbar-track-piece {
  background-color: #F1F1F3;
}

div::-webkit-resizer {
  background-color: #F1F1F3;
}

div::-webkit-scrollbar {
  width: 6px;
  height :6px;
  background: transparent;
}

/*
div::-webkit-scrollbar:hover {
  background: #fafafa;
}
*/

div::-webkit-scrollbar-thumb {
  background: #D7D7D9;
  background-clip: padding-box;
  height: 6px;
  border: 0px solid rgba(0, 0, 0, 0);
  -webkit-border-radius: 8px;
}

div:hover::-webkit-scrollbar-thumb {
  background : #B0B0B2;
  background-clip: padding-box;
  height: 6px;
  border: 0px solid rgba(0, 0, 0, 0);
  -webkit-border-radius: 8px;
}

/*
div::-webkit-scrollbar-thumb:hover {
  background : hsla(255, 0%, 30%, 1);
  background-clip: padding-box;
  height: 6px;
  border: 2px solid rgba(0, 0, 0, 0);
  -webkit-border-radius: 8px;
}
*/

div::-webkit-scrollbar-corner {
  background: transparent;
  border-style: solid;
  border-color: transparent;
  border-width: 0px 0 0 0px;
}

[calc-scrollable-suites-list-height] {
  height: calc(100vh - 288px)!important;
  /*overflow-x: none;
  overflow-y: scroll;*/
}

[calc-suites-left-column] {
  height: calc(100vh - 236px)!important;
  /*overflow-x: none;
  overflow-y: scroll;*/
}

.generic-button {
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

div[css-invisble-scrollbar]::-webkit-scrollbar, .onboarding-wizard__stepper::-webkit-scrollbar {
  width: 0px;
  height :0px;
  background: transparent;
}

[touch-action="none"] {
  touch-action: none;
}

.generic-select-dropdown__chosen-item-text {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

iframe.embedly-embed {
  border: 0;
}